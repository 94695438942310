import React from 'react'

import Layout from "../components/layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const SentPage  = () => (
    <Layout>
        <section className="section" style={{height:"400px"}}>
            <div className="container">
                <h1 className="title"> <span class="icon"><FontAwesomeIcon icon={faCheck} /></span> Votre message a été envoyé !</h1>
                <h2 className="subtitle">Merci d'avoir pris contact avec nous</h2>
            </div>
        </section>
    </Layout>
)

export default SentPage;
